<template>
  <div>
    <h3 class="my-4">Password</h3>
    <div class="bg-white p-3 mycard">
      <div v-if="!changePassword">
        <b-row>
          <b-col cols="6">
            <p class="title">Password</p>
          </b-col>
          <b-col cols="6">
            <p>************</p>
          </b-col>
        </b-row>
        <div class="text-center my-3">
          <button class="mybtn-blue" @click="openChangePassword">
            change password
          </button>
        </div>
      </div>

      <!-- change password  -->
      <b-form
        v-else
        class="bg-white shadow-lg p-3 mt-3 mb-4 mycard position-relative"
        @submit="handleChangePassword"
      >
        <i
          class="fas fa-window-close position-absolute closeIcon"
          @click="closeChangePasswordPage"
        ></i>
        <b-form-group class="px-3 mt-4">
          <label>Current Password</label>
          <b-form-input
            class="my-input"
            v-model="oldPassword"
            type="password"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group class="px-3">
          <label>New Password</label>
          <div class="position-relative">
            <b-form-input
              v-model="newPassword"
              :state="pwValidation"
              :type="getPasswordDisplayStatus"
              class="my-input"
              required
            ></b-form-input>
          </div>
          <b-form-invalid-feedback :state="pwValidation">
            <p class="m-2 text-danger" style="font-size: 15px">
              * Must be at least 8 characters
            </p>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="px-3">
          <label>Confirm New Password</label>
          <div class="position-relative">
            <b-form-input
              v-model="newPasswordAgain"
              :state="newPWValidation"
              :type="getPasswordDisplayStatus"
              class="my-input"
              required
            ></b-form-input>
          </div>
          <b-form-invalid-feedback :state="newPWValidation">
            <p class="m-2 text-danger" style="font-size: 15px">
              * Two passwords do not match.
            </p>
          </b-form-invalid-feedback>
          <b-form-checkbox
            class="my-3"
            v-model="passwordDisplay"
            name="checkbox"
            value="show"
            unchecked-value="hide"
          >
            <p class="m-0">show password</p>
          </b-form-checkbox>
        </b-form-group>
        <div class="d-flex justify-content-center my-3">
          <button class="mybtn-blue" :disabled="disableConfirmButton">
            Confirm
          </button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      changePassword: false,
      passwordDisplay: "hide",
      isShowPassword: true,
      oldPassword: "",
      newPassword: "",
      newPasswordAgain: "",
    };
  },
  methods: {
    closeChangePasswordPage() {
      this.isShowPassword = false;
      this.changePassword = false;
      this.oldPassword = "";
      this.newPassword = "";
      this.newPasswordAgain = "";
    },
    openChangePassword() {
      this.changePassword = true;
    },
    handleChangePassword(event) {
      event.preventDefault();
      if (this.newPassword !== this.newPasswordAgain) {
        this.$notify({
          title: "Warning",
          message: "Your two new password are not the same !",
          type: "warning",
        });
      } else if (this.newPassword === this.oldPassword) {
        this.$notify({
          title: "Warning",
          message:
            "Your new password can be the same as the current password !",
          type: "warning",
        });
      } else {
        this.$axios
          .post("/Account/ChangePassword",{
            OldPassword: this.oldPassword,
            NewPassword: this.newPassword
          })
          .then((response) => {
            if (response.data === "Old Password incorrect") {
              this.$notify.error({
                title: "Error !",
                message: "Old Password Incorrect !",
              });
            } else {
              this.closeChangePasswordPage();
              this.$notify({
                title: "Success",
                message: "Change your password successfully !",
                type: "success",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify.error({
              title: "Error !",
              message: "Network Error !",
            });
          });
      }
    },
  },
  computed: {
    disableConfirmButton() {
      let disbaledButton = false;
      if (this.newPassword != this.newPasswordAgain) {
        disbaledButton = true;
      }
      if (this.newPassword.length < 8) {
        disbaledButton = true;
      }
      return disbaledButton;
    },
    newPWValidation() {
      return (
        this.newPassword === this.newPasswordAgain && this.newPassword !== ""
      );
    },
    pwValidation() {
      return this.newPassword.length >= 8;
    },
    getPasswordDisplayStatus() {
      return this.passwordDisplay === "show" ? "text" : "password";
    },
  },
};
</script>

<style lang="scss" scoped></style>
