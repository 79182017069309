<template>
  <div>
    <div class="mobileNav">My Profile</div>
    <b-container class="profile">
      <div v-if="$store.state.isTermsOpened">
        <TermsAndConditions />
      </div>
      <div v-else>
        <b-row class="p-3">
          <b-col cols="12" md="6" class="my-3 px-4">
            <PersonalProfile />
            <ChangePassword />
          </b-col>
          <b-col cols="12" md="6" class="my-3 px-4">
            <AutoPayment />
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import PersonalProfile from "./ProfileComponents/PersonalProfile";
import ChangePassword from "./ProfileComponents/ChangePassword";
import AutoPayment from "./ProfileComponents/AutoPayment";
import TermsAndConditions from "./ProfileComponents/TermsAndConditions";

export default {
  name: "Profile",
  data() {
    return {
      terminationStatus: "",
      terminationDate: "",
    };
  },
  components: {
    PersonalProfile,
    ChangePassword,
    AutoPayment,
    TermsAndConditions,
  },
  mounted() {
    this.$store.commit("setCurrentNav", "Profile");

    this.$axios
      .get("Account/GetStatus")
      .then((response) => {
        let data = JSON.parse(response.data);
        this.terminationStatus = data.TerminationStatus;
        this.terminationDate = data.terminationDate;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss">
.profile {
  h3 {
    font-size: 22px;
  }

  .closeIcon {
    font-size: 24px;
    top: 14px;
    right: 14px;
  }

  .title {
    font-weight: 500;
  }
}
</style>
