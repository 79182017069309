<template>
  <div>
    <h3 class="mb-4">My Profile</h3>
    <p>
      This information is used to contact you when your bill is ready and for
      account-related communications.
    </p>
    <div class="bg-white p-3 mycard">
      <!-- name  -->
      <b-row v-if="!changeName">
        <b-col cols="4">
          <p class="title">Name</p>
        </b-col>
        <b-col cols="6">
          <p>{{ name }}</p>
        </b-col>
        <b-col cols="2">
          <i
            class="fas fa-pen mx-2"
            @click="openChangeName"
            style="font-size: 12px"
            v-show="isEdit"
          ></i>
        </b-col>
      </b-row>
      <!-- change name  -->
      <div
        v-else
        class="bg-white shadow-lg p-3 mt-3 mb-4 mycard position-relative"
      >
        <i
          class="fas fa-window-close position-absolute closeIcon"
          @click="closeChangeNamePage"
        ></i>
        <div class="p-3">
          <label>New First Name</label>
          <b-input v-model="firstName" class="my-input" type="text"></b-input>
        </div>
        <div class="p-3">
          <label>New Last Name</label>
          <b-input v-model="lastName" class="my-input" type="text"></b-input>
        </div>
        <div class="d-flex justify-content-center my-3">
          <button class="mybtn-blue" @click="handleChangeName">Save</button>
        </div>
      </div>

      <!-- address  -->
      <b-row v-if="!changeAddress">
        <b-col cols="4">
          <p class="title">Address</p>
        </b-col>
        <b-col cols="6">
          <p>
            {{ address }}
          </p>
        </b-col>
        <b-col cols="2">
          <i
            class="fas fa-pen mx-2"
            @click="openChangeAddress"
            style="font-size: 12px"
            v-show="isEdit"
          ></i>
        </b-col>
      </b-row>
      <!-- change address -->
      <div
        v-else
        class="bg-white shadow-lg p-3 mt-3 mb-4 mycard position-relative"
      >
        <i
          class="fas fa-window-close position-absolute closeIcon"
          @click="closeChangeAddressPage"
        ></i>
        <div class="p-2">
          <label>Address</label>
          <b-input v-model="street" class="my-input"></b-input>
        </div>
        <div class="p-2">
          <b-row>
            <b-col>
              <label>City</label>
              <br />
              <b-input v-model="city" type="text" class="my-input" />
            </b-col>
            <b-col>
              <label>Province</label>
              <br />
              <b-input v-model="state" type="text" class="my-input" />
            </b-col>
          </b-row>
        </div>
        <div class="p-2">
          <b-row>
            <b-col>
              <label>Country</label>
              <br />
              <b-input v-model="country" type="text" class="my-input" />
            </b-col>
            <b-col>
              <label>Post Code</label>
              <br />
              <b-input v-model="zip" type="text" class="my-input" />
            </b-col>
          </b-row>
        </div>
        <div class="d-flex justify-content-center my-3">
          <button class="mybtn-blue" @click="handleChangeAddress">Save</button>
        </div>
      </div>

      <!-- phone  -->
      <b-row>
        <b-col cols="4">
          <p class="title">Phone</p>
        </b-col>
        <b-col cols="6">
          <p>{{ number }}</p>
        </b-col>
        <b-col cols="2">
          <i class="fas fa-pen d-none"></i>
        </b-col>
      </b-row>

      <!-- email  -->
      <b-row v-if="!changeEmail">
        <b-col cols="4">
          <p class="title">Email</p>
        </b-col>
        <b-col cols="6">
          <p>{{ processEmail }}</p>
        </b-col>
        <b-col cols="2">
          <i
            class="fas fa-pen mx-2"
            @click="openChangeEmail"
            style="font-size: 12px"
            v-show="isEdit"
          ></i>
        </b-col>
      </b-row>
      <!-- change email  -->
      <div
        v-else
        class="bg-white shadow-lg p-3 mt-3 mb-4 mycard position-relative"
      >
        <i
          class="fas fa-window-close position-absolute closeIcon"
          @click="closeChangeEmailPage"
        ></i>
        <div class="p-3">
          <label>New Email Address</label>
          <b-input v-model="newEmail" class="my-input" type="email"></b-input>
        </div>
        <div class="p-3">
          <label>Confirm Email Address</label>
          <b-input
            v-model="newEmailAgain"
            class="my-input"
            type="email"
          ></b-input>
        </div>
        <div class="d-flex justify-content-center my-3">
          <button class="mybtn-blue" @click="openTheConfirmationMsgBox">
            Save
          </button>
        </div>
      </div>

      <div v-if="!isEdit" class="text-center m-3">
        <button class="mybtn-blue" @click="openEdit">Edit</button>
      </div>
      <div v-else class="text-center m-3">
        <button class="mybtn-blue" @click="isEdit = false">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // status
      isEdit: false,
      changeName: false,
      changeAddress: false,
      changeEmail: false,
      // base info
      name: "",
      firstName: "",
      lastName: "",
      number: "",
      email: "",
      // new info
      newEmail: "",
      newEmailAgain: "",
      address: "",
      street: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    };
  },
  methods: {
    getProfile() {
      this.$axios
        .get("/Account/GetProfile")
        .then((response) => {
          let data = JSON.parse(response.data);
          this.firstName = data.FirstName;
          this.lastName = data.LastName;
          this.name = data.FirstName + " " + data.LastName;
          this.number = data.PhoneNumber;
          this.address = data.Address;
          let addressData = this.address.split(",");
          this.street = addressData[0];
          this.city = addressData[1];
          this.state = addressData[2];
          this.country = addressData[3];
          this.zip = addressData[4];
          this.email = data.Email;
          this.newEmail = data.Email;
          this.newEmailAgain = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openEdit() {
      this.isEdit = true;
    },
    handleChangeAddress() {
      if (
        this.street === "" ||
        this.city === "" ||
        this.state === "" ||
        this.country === "" ||
        this.zip === ""
      ) {
        this.$notify({
          title: "Warning",
          message: "Please input your information completely !",
          type: "warning",
        });
      } else {
        let queryUrl =
          "?address=" +
          this.street +
          ", " +
          this.city +
          ", " +
          this.state +
          ", " +
          this.country +
          ", " +
          this.zip;
        // process queryUrl: replace ' ' by '%20' & replace ',' by '%2C'
        queryUrl = queryUrl.replace(/,/g, "%2C");
        queryUrl = queryUrl.replace(/ /g, "%20");
        this.$axios
          .post("/Account/ChangeAddress" + queryUrl)
          .then(() => {
            this.$notify({
              title: "Success",
              message: "Changed your address successfully !",
              type: "success",
            });
            this.getProfile();
          })
          .catch((error) => {
            console.log(error);
            this.$notify.error({
              title: "Error !",
              message: "Network Error !",
            });
          });
        this.closeChangeAddressPage();
      }
    },
    handleChangeName() {
      if (this.firstName === "" || this.lastName === "") {
        this.$notify({
          title: "Warning",
          message: "Your first name or last name can not be empty !",
          type: "warning",
        });
      } else {
        let queryUrl =
          "?firstName=" + this.firstName + "&lastName=" + this.lastName;
        this.$axios
          .post("/Account/ChangeName" + queryUrl)
          .then((response) => {
            if (response.data === "success") {
              this.$notify({
                title: "Success",
                message: "Change your name successfully !",
                type: "success",
              });
              this.getProfile();
            } else {
              this.$notify({
                title: "Error",
                message: "Failed to change your name !",
                type: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error);
            this.$notify.error({
              title: "Error !",
              message: "Network Error !",
            });
          });
        this.closeChangeNamePage();
      }
    },
    handleChangeEmail() {
      let queryUrl = "?email=" + this.newEmail;
      this.$axios
        .post("/Account/ChangeEmail" + queryUrl)
        .then(() => {
          this.closeChangeEmailPage();
          this.$notify({
            title: "Success",
            message: "Change your email successfully !",
            type: "success",
          });
          this.getProfile();
        })
        .catch((error) => {
          console.log(error);
          this.$notify.error({
            title: "Error !",
            message: "Network Error !",
          });
        });
    },
    closeChangeAddressPage() {
      this.changeAddress = false;
      this.getProfile();
    },
    closeChangeNamePage() {
      this.changeName = false;
      this.getProfile();
    },
    closeChangeEmailPage() {
      this.changeEmail = false;
      this.getProfile();
    },
    openChangeAddress() {
      this.changeAddress = true;
      this.closeChangeNamePage();
      this.closeChangeEmailPage();
    },
    openChangeEmail() {
      this.changeEmail = true;
      this.closeChangeNamePage();
      this.closeChangeAddressPage();
    },
    openChangeName() {
      this.changeName = true;
      this.closeChangeAddressPage();
      this.closeChangeEmailPage();
    },
    openTheConfirmationMsgBox() {
      if (this.newEmail === "" || this.newEmailAgain === "") {
        this.$notify({
          title: "Warning",
          message: "Your new email can not be empty !",
          type: "warning",
        });

        return;
      } else if (this.newEmail !== this.newEmailAgain) {
        this.$notify({
          title: "Warning",
          message: "Your two new email inputs are not the same !",
          type: "warning",
        });

        return;
      }

      this.$confirm(
        `Your current login username will remain the same. Do you still want to change to a new email address?`,
        "Confirmation",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.handleChangeEmail();
        })
        .catch(() => {
          //this.drawer = false;
          this.$notify({
            title: "Error",
            message: "Your request has been cancelled.",
            type: "error",
          });
        });
    },
  },
  mounted() {
    this.getProfile();
  },
  computed: {
    processEmail() {
      let data = this.email.split("@");
      return data[0] + "\n" + "@" + "\n" + data[1];
    },
  },
};
</script>

<style lang="scss" scoped></style>
