<template>
  <div>
    <h3 class="mb-4">Auto Payment Set Up</h3>
    <div class="bg-white p-2 mycard">
      <div v-if="!changeCreditCard && !changePadPayment">
        <div v-if="isAutoPayment">
          <div
            class="bg-blue text-white shadow my-5 p-3 creditCard position-relative"
          >
            <p style="font-size: 18px">
              <span>Name</span>
              <br />
              <span>
                {{ cc.name }}
              </span>
            </p>
            <b-row class="cardNumber">
              <b-col cols="2">
                <p>....</p>
              </b-col>
              <b-col cols="2">
                <p>....</p>
              </b-col>
              <b-col cols="2">
                <p>....</p>
              </b-col>
              <b-col cols="2">
                <p>{{ cc.last4Digits }}</p>
              </b-col>
            </b-row>
            <p>
              <span>Expiry Date</span> <br />
              <span> {{ cc.month }} / {{ cc.year[2] + cc.year[3] }}</span>
            </p>
            <img
              class="cardImage"
              :src="require('../../../assets/creditCards/' + cc.type + '.png')"
            />
          </div>
        </div>
        <div v-else-if="billingType.DirectDeposit">
          <div
            class="bg-blue text-white shadow my-5 p-3 creditCard position-relative"
          >
            <b-row>
              <b-col>
                <p style="font-size: 24px">
                  <span>Bank Information</span>
                </p>
              </b-col>
              <b-col>
                <img
                  class="bankLogo"
                  :src="
                    require('../../../assets/bankLogos/' +
                      selectBankImgForPad())
                  "
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col> <p style="font-size: 18px">Account Holder</p></b-col>
              <b-col
                ><p style="font-size: 18px">
                  {{ padUIInfo.AccountHolder }}
                </p></b-col
              >
            </b-row>

            <b-row>
              <b-col> <p style="font-size: 18px">Transit #</p></b-col>
              <b-col
                ><p style="font-size: 18px">
                  {{ padUIInfo.TransitNumber }}
                </p></b-col
              >
            </b-row>

            <b-row>
              <b-col> <p style="font-size: 18px">Institution #</p></b-col>
              <b-col
                ><p style="font-size: 18px">
                  {{ padUIInfo.BankInstitution }}
                </p></b-col
              >
            </b-row>

            <b-row>
              <b-col> <p style="font-size: 18px">Account #</p></b-col>
              <b-col
                ><p style="font-size: 18px">
                  ****{{ padUIInfo.AccountNumberLast4Digits }}
                </p></b-col
              >
            </b-row>
          </div>
        </div>
        <div v-else>
          <!-- <div
            class="m-5 p-4 mycard text-white bg-blue shadow"
            style="border-radius: 10px"
          > -->
          <!-- <p class="m-0 text-center" style="font-size: 18px">
              Add an auto payment method here
            </p> -->
          <div
            v-if="!showAutoPaymentOptions"
            style="text-align: center; margin-top: 1.5em"
          >
            <button
              class="longButtonText"
              @click="showAutoPaymentOptions = true"
            >
              Add an auto payment method here
            </button>
          </div>
          <!-- </div> -->
        </div>

        <div class="my-4">
          <div v-if="showAutoPaymentOptions">
            <b-row class="text-center my-3">
              <b-col class="mb-2">
                <div v-if="!billingType.DirectDeposit">
                  <button class="mybtn-blue" @click="changeCreditCard = true">
                    <div v-show="isAutoPayment">Change Credit Card</div>
                    <div v-show="!isAutoPayment">Add your credit card</div>
                  </button>
                </div>
              </b-col>
            </b-row>

            <b-row class="text-center my-3">
              <b-col class="mb-2">
                <div v-if="!isAutoPayment">
                  <button
                    :disabled="isPadBlocked"
                    :class="isPadBlocked ? 'mybtn-block' : 'mybtn-blue'"
                    @click="changePadPayment = true"
                  >
                    <div v-show="billingType.DirectDeposit">
                      Change Bank Details
                    </div>
                    <div v-show="!billingType.DirectDeposit">
                      Add Your Bank Account
                    </div>
                  </button>
                </div>
              </b-col>
            </b-row>
          </div>

          <div v-if="isAutoPayment">
            <b-row class="text-center my-3">
              <b-col class="mb-2">
                <button
                  @click="openTheConfirmationMsgBox('credit card')"
                  class="longButtonText"
                >
                  Cancel Credit Card Auto Payment
                </button>
              </b-col>
            </b-row>
          </div>
          <div v-else-if="billingType.DirectDeposit">
            <b-row class="text-center my-3">
              <b-col class="mb-2">
                <button
                  :disabled="isPadBlocked"
                  @click="openTheConfirmationMsgBox('pad')"
                  :class="
                    isPadBlocked ? 'longButtonTextBlocked' : 'longButtonText'
                  "
                >
                  Cancel Pre-Authorized Debit
                </button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <!-- modify credit card info payment  -->
      <div
        v-else-if="changeCreditCard"
        class="bg-white shadow-lg p-3 mt-3 mb-4 mycard position-relative"
      >
        <i
          class="fas fa-window-close position-absolute closeIcon"
          @click="resetCreditCardForm"
        ></i>

        <h3 class="title text-info text-center my-4" v-show="isAutoPayment">
          Modify
        </h3>
        <h3 class="title text-info text-center my-4" v-show="!isAutoPayment">
          Add
        </h3>

        <b-form @submit="handleChangeCreditCard">
          <b-row>
            <b-col cols="12" md="6" class="mb-3">
              <label>Credit Card Number</label>
              <b-form-input
                v-model="card.number"
                class="my-input"
                :state="numberState"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                Incorrect Credit Card Number
              </b-form-invalid-feedback>
            </b-col>

            <b-col cols="12" md="6" class="mb-3">
              <label>Card Holder Name</label>
              <b-form-input
                v-model="card.name"
                class="my-input"
                :state="nameState"
                required
              ></b-form-input>

              <b-form-invalid-feedback>
                Please enter your full name
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-3">
              <label>Expiration Date (MM/YY)</label>
              <b-form-input
                v-model="card.date"
                class="my-input"
                :state="dateState"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                Correct format: MM/YY <br />
                (MM from 01 - 12)
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="12" md="6" class="mb-3">
              <label>CVV</label>
              <b-form-input
                v-model="card.cvv"
                class="my-input"
                :state="cvvState"
                required
              ></b-form-input>
              <b-form-invalid-feedback>
                Please enter the 3 digits on the back of your card
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center my-4 px-5">
            <button type="submit" class="mybtn-blue">
              Save
            </button>
          </b-row>
        </b-form>
      </div>

      <!-- modify pad payment info -->
      <div
        v-else-if="changePadPayment"
        class="bg-white shadow-lg p-3 mt-3 mb-4 mycard position-relative"
      >
        <i
          class="fas fa-window-close position-absolute closeIcon"
          @click="resetPadForm"
        ></i>

        <!-- <h3 class="title text-info text-center my-4" v-show="isAutoPayment">
          Modify
        </h3>
        <h3 class="title text-info text-center my-4" v-show="!isAutoPayment">
          Add
        </h3> -->

        <h3 class="title text-info text-center my-4">
          Add
        </h3>

        <img
          src="../../../assets/VOID_Cheque.png"
          width="100%"
          alt="void_cheque"
        />

        <b-form @submit.prevent class="mt-3">
          <b-row>
            <b-col cols="12" md="6" class="mb-3">
              <label class="padFormText"
                >Transit Number
                <span class="padFormText" style="font-weight: 700"
                  >(5 digits)</span
                ></label
              >
              <b-form-input
                v-model="padInfo.TransitNumber"
                class="my-input"
                :state="transitNumberState"
              ></b-form-input>

              <b-form-invalid-feedback>
                Please enter a 5 digit number
              </b-form-invalid-feedback>
            </b-col>

            <b-col cols="12" md="6" class="mb-3">
              <label class="padFormText"
                >Institution Number
                <span class="padFormText" style="font-weight: 700"
                  >(3 digits)</span
                ></label
              >
              <b-form-input
                v-model="padInfo.BankInstitution"
                class="my-input"
                :state="bankInstitutionState"
              ></b-form-input>
              <b-form-invalid-feedback>
                Please enter a 3 digit number
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mb-3">
              <label class="padFormText"
                >Account Number
                <span class="padFormText" style="font-weight: 700"
                  >(7-12 digits)</span
                ></label
              >
              <b-form-input
                v-model="padInfo.AccountNumber"
                class="my-input"
                :state="accountNumberState"
              ></b-form-input>
              <b-form-invalid-feedback>
                Please enter a 7-12 digit number
              </b-form-invalid-feedback>
            </b-col>
            <b-col cols="12" md="6" class="mb-3">
              <label>Account Holder's Name</label>
              <b-form-input
                v-model="padInfo.AccountHolder"
                class="my-input"
                :state="cvvState"
              ></b-form-input>
              <b-form-invalid-feedback>
                Please enter the 3 digits on the back of your card
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center my-4 px-5">
            <div v-if="this.fileName === ''">
              <div
                @drop.prevent="dropFile"
                @dragover="dragover"
                @dragleave="dragleave"
                @dragover.prevent
                style="text-align: center"
                class="px-5 bordered largeGaps bg-light"
              >
                <p>
                  Drag &amp; drop or click the upload button to upload
                </p>
                <label class="custom-file-upload">
                  <input type="file" @change="previewFiles" multiple />
                  Upload
                </label>
              </div>
            </div>

            <span v-show="this.fileName !== ''">
              File Name:
              <span style="font-weight: 700">{{ this.fileName }}</span>
              <p
                class="removeFile"
                v-show="this.fileName !== ''"
                @click="removePadFile"
              >
                remove
              </p>
            </span>

            <b-form-checkbox
              class="mt-3"
              v-model="padCheck1"
              value="accepted"
              unchecked-value="not_accepted"
            >
              You are agreeing to the
              <span class="terms" @click="toggleTerms()">
                Terms &amp; Conditions
              </span>
              under PhoneBox.
            </b-form-checkbox>
            <b-form-checkbox
              class="mt-3"
              v-model="padCheck2"
              value="accepted"
              unchecked-value="not_accepted"
            >
              I agree that the information provided is correct based on the VOID
              cheque uploaded. NSF penalty fee will occur on any incorrect
              information or non-sufficient balance under the account.
            </b-form-checkbox>
            <button
              :disabled="!validatePadForm()"
              type="submit"
              :class="
                validatePadForm() ? 'mybtn-blue mt-3' : 'mybtn-block mt-3'
              "
              @click="handleChangePadPayment()"
            >
              Save
            </button>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../../store/index";

export default {
  data() {
    return {
      changeCreditCard: false,
      isAutoPayment: false,
      changePadPayment: false,
      isPadPayment: false,

      // PAD Payment Setup - Checkbox Validation
      padCheck1: false,
      padCheck2: false,

      // File Upload - Input
      fileName: "",

      // Block from 13th to 25th of every month.
      isPadBlocked: false,

      showAutoPaymentOptions: false,

      cc: {
        name: "",
        last4Digits: "",
        type: "",
        month: "",
        year: "",
      },
      card: {
        name: "",
        number: "",
        date: "",
        cvv: "",
      },
      padInfo: {
        BankInstitution: "",
        TransitNumber: "",
        AccountNumber: "",
        AccountHolder: "",
        ProofImage: [],
      },
      billingType: {
        Self: false,
        AutoPay: false,
        DirectDeposit: false,
      },
      padUIInfo: {
        AccountHolder: "",
        AccountNumberLast4Digits: "",
        BankInstitution: "",
        TransitNumber: "",
      },
    };
  },
  computed: {
    numberState() {
      if (this.card.number === "") {
        return null;
      } else {
        //Luhn algorithm
        let cardNo = this.card.number;
        if (cardNo === "") {
          return false;
        }
        let s = 0;
        let doubleDigit = false;
        for (let i = cardNo.length - 1; i >= 0; i--) {
          let digit = +cardNo[i];
          if (doubleDigit) {
            digit *= 2;
            if (digit > 9) digit -= 9;
          }
          s += digit;
          doubleDigit = !doubleDigit;
        }
        return s % 10 == 0;
      }
    },
    nameState() {
      return this.card.name === "" ? null : true;
    },
    dateState() {
      if (this.card.date === "") {
        return null;
      } else {
        let data = this.card.date;
        if (data.length !== 5) {
          return false;
        } else if (!data.includes("/")) {
          return false;
        } else {
          let month = data.split("/")[0];
          let year = data.split("/")[1];
          if (
            month.length === 2 &&
            year.length === 2 &&
            this.isNumber(month) &&
            this.isNumber(year) &&
            parseInt(month) >= 1 &&
            parseInt(month) <= 12
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    cvvState() {
      if (this.card.cvv === "") {
        return null;
      } else {
        return (
          this.isNumber(this.card.cvv) &&
          (this.card.cvv.length === 3 || this.card.cvv.length === 4)
        );
      }
    },
    transitNumberState() {
      if (this.padInfo.TransitNumber === "") {
        return null;
      } else {
        if (this.padInfo.TransitNumber.length === 5) {
          return true;
        } else {
          return false;
        }
      }
    },
    bankInstitutionState() {
      if (this.padInfo.BankInstitution === "") {
        return null;
      } else {
        if (this.padInfo.BankInstitution.length === 3) {
          return true;
        } else {
          return false;
        }
      }
    },
    accountNumberState() {
      if (this.padInfo.AccountNumber === "") {
        return null;
      } else {
        if (
          this.padInfo.AccountNumber.length >= 7 &&
          this.padInfo.AccountNumber.length <= 12
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    isNumber(n) {
      return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    },
    resetCreditCardForm() {
      this.card.name = "";
      this.card.number = "";
      this.card.date = "";
      this.card.cvv = "";

      this.changeCreditCard = false;
    },
    resetPadForm() {
      this.padInfo.BankInstitution = "";
      this.padInfo.TransitNumber = "";
      this.padInfo.AccountNumber = "";
      this.padInfo.AccountHolder = "";
      this.padInfo.ProofImage = "";
      this.fileName = "";

      this.padCheck1 = false;
      this.padCheck2 = false;

      this.changePadPayment = false;
    },
    openTheConfirmationMsgBox(type) {
      this.$confirm(
        "Are you sure you want to cancel your auto payment?",
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      ).then(() => {
        if (type === "credit card") {
          this.changeMOPToCash()
          setTimeout(() => {
            this.resetCreditCardForm();
            this.getProfile();
            this.$notify({
              title: "Success",
              message: "Successfully cancelled credit card autopayment!",
              type: "success",
            });
          }, 2000);
          // uncomment this? it's unnecessary 
          // this.setAutoPayment(false);
        } else {
          this.$notify({
            title: "Success",
            message: "Successfully cancelled pre-authorized debit autopayment!",
            type: "success",
          });
          this.changeMOPToCash();
        }
      });
    },
    changeMOPToCash() {
      let formData = new FormData();
      formData.append("PaymentMethod", "Cash");

      // logging out data
      // for (let key of formData.entries()) {
      //   console.log(key[0] + ", " + key[1]);
      // }

      this.$axios
        .post("/Account/ChangePaymentMethod", formData)
        .then((response) => {
          // if (response.data) {
          //   this.$notify({
          //     title: "Success",
          //     message: "Successfully add your credit card to auto payment !",
          //     type: "success",
          //   });
          // } else {
          //   this.$notify({
          //     title: "Success",
          //     message: "Successfully cancel your credit card auto payment !",
          //     type: "success",
          //   });
          // }
          if (response.data === "") {
            this.billingType.DirectDeposit = false;
          }
          //console.log("THEN changeMOPToCash()", response);
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Error",
            message: "Sorry something went wrong",
            type: "error",
          });
        });
    },
    previewFiles(event) {
      // console.log("previewFiles() event.target.files", event.target.files);
      // console.log(
      //   "previewFiles() event.target.files[0]",
      //   event.target.files[0]
      // );

      this.padInfo.ProofImage = event.target.files[0];
      //console.log("this.padInfo.ProofImage", this.padInfo.ProofImage);

      this.fileName = this.padInfo.ProofImage.name;
      //console.log("this.padInfo.ProofImage", this.padInfo.ProofImage.name);
    },
    setAutoPayment(value) {
      this.$axios
        .post("/Account/ChangeAutoPayment?autopayment=" + value)
        .then(() => {
          this.resetCreditCardForm();
          this.getProfile();
          if (value) {
            this.$notify({
              title: "Success",
              message: "Successfully added credit card autopayment!",
              type: "success",
            });
          } else {
            this.changeMOPToCash();
            this.$notify({
              title: "Success",
              message: "Successfully cancelled credit card autopayment!",
              type: "success",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Error",
            message: "Network Error!",
            type: "error",
          });
        });
    },
    handleChangeCreditCard(event) {
      event.preventDefault();
      if (
        this.numberState &&
        this.nameState &&
        this.dateState &&
        this.cvvState
      ) {
        this.changeCreditCard = false;

        let formData = new FormData();

        formData.append("PaymentMethod", "Credit Card");
        formData.append("CreditCardNumber", this.card.number);
        formData.append("CreditCardName", this.card.name);
        formData.append("CCV", this.card.cvv);
        formData.append("ExpirationMonth", this.card.date.split("/")[0]);
        formData.append("ExpirationYear", "20" + this.card.date.split("/")[1]);

        // let data = {
        //   CreditCardNumber: this.card.number,
        //   CreditCardName: this.card.name,
        //   CCV: this.card.cvv,
        //   ExpirationMonth: this.card.date.split("/")[0],
        //   ExpirationYear: "20" + this.card.date.split("/")[1],
        // };

        this.$axios
          .post("/Account/ChangePaymentMethod", formData)
          // this is where we used to use .then() setAutoPayment
          .catch((error) => {
            console.log(error);
            this.$notify({
              title: "Error",
              message:
                "Sorry, failed to set up auto payment with your credit card !",
              type: "error",
            });
          });
      } else {
        this.$notify({
          title: "Error",
          message: "Please make sure all information you entered is correct.",
          type: "error",
        });
      }
    },
    handleChangePadPayment() {
      // let s =
      //   this.padInfo.BankInstitution +
      //   this.padInfo.TransitNumber +
      //   this.padInfo.AccountNumber +
      //   this.padInfo.AccountHolder +
      //   this.padInfo.ProofImage;
      // console.log(s);

      if (this.padCheck1 !== "accepted" || this.padCheck2 !== "accepted") {
        this.$notify({
          title: "Error",
          message: "Please confirm our terms and conditions",
          type: "error",
        });
        return;
      }

      let formData = new FormData();

      formData.append("PaymentMethod", "Bank Account");
      formData.append("BankInstitution", this.padInfo.BankInstitution);
      formData.append("TransitNumber", this.padInfo.TransitNumber);
      formData.append("AccountNumber", this.padInfo.AccountNumber);
      formData.append("AccountHolder", this.padInfo.AccountHolder);
      formData.append("ProofImage", this.padInfo.ProofImage);

      // logging out data
      // for (let key of formData.entries()) {
      //   console.log(key[0] + ", " + key[1]);
      // }

      this.$axios
        .post("/Account/ChangePaymentMethod", formData)
        .then((response) => {
          //console.log("THEN handleChangePadPayment()", response);
          if (response.data != "") {
            this.$notify({
              title: "Error",
              message:
                "Sorry, the information you entered is wrong. Please try again.",
              type: "error",
            });
            return;
          } else {
            this.$notify({
              title: "Success",
              message: "Successfully added pre-authorized debit autopayment!",
              type: "success",
            });
            this.resetPadForm();
          }
          this.changePadPayment = false;
          this.isAutoPayment = false;
          this.isPadPayment = true;
          this.billingType.DirectDeposit = true;
          this.getProfile();
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            title: "Error",
            message: "Sorry something went wrong",
            type: "error",
          });
        });

      //console.log("handleChangePadPayment() END");
    },
    getProfile() {
      this.$axios
        .get("/Account/GetProfile")
        .then((response) => {
          let data = JSON.parse(response.data);
          //console.log("/Account/GetProfile", data);
          console.log("data from get rpofile: : ", data);
          this.isAutoPayment = data.AutoPayment;
          if (this.isAutoPayment) {
            this.cc.last4Digits = data.CreditCardLast4Digits;
            this.cc.name = data.CreditCardHolderName;
            this.cc.month = data.CreditCardExpirationMonth;
            this.cc.year = data.CreditCardExpirationYear;
            // Visa Master AmericanExpress Discover DinersClub JCB
            this.cc.type = data.CreditCardType;
          }

          // New Nov 10, 2021 - Yang
          // Check for BillingType
          // Auto Pay means Credit Card is set up
          // Self means Cash a.k.a nothing is set up
          // Direct Deposit means PAD payment is set up
          if (data.BillingType === "Self") {
            this.billingType.Self = true;
          } else if (data.BillingType === "Auto Pay") {
            this.billingType.AutoPay = true;
          } else if (data.BillingType === "Direct Deposit") {
            this.billingType.DirectDeposit = true;
            this.padUIInfo.AccountHolder = data.AccountHolder;
            this.padUIInfo.AccountNumberLast4Digits =
              data.AccountNumberLast4Digits;
            this.padUIInfo.BankInstitution = data.BankInstitution;
            this.padUIInfo.TransitNumber = data.TransitNumber;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleTerms() {
      store.commit("setIsTermsOpened", true);
    },
    validatePadForm() {
      if (
        this.padInfo.AccountHolder === "" ||
        this.padInfo.AccountNumber === "" ||
        this.padInfo.BankInstitution === "" ||
        this.padInfo.TransitNumber === "" ||
        this.fileName === ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    dropFile(e) {
      let droppedFiles = e.dataTransfer.files;
      //console.log("droppedFiles", droppedFiles[0]);

      this.padInfo.ProofImage = droppedFiles[0];

      this.fileName = this.padInfo.ProofImage.name;
    },
    dragover(event) {
      event.preventDefault();
      // console.log(
      //   "event.currentTarget.classList",
      //   event.currentTarget.classList
      // );
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-success")) {
        event.currentTarget.classList.remove("bg-light");
        event.currentTarget.classList.add("bg-success");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-light");
      event.currentTarget.classList.remove("bg-success");
    },
    // ‘Change Payment method’ and ‘Cancel PAD Auto Payment’ button under PAD will be disabled (greyed out) during the period of 13th to 25th of every month.
    validateDateForPad() {
      const d = new Date();
      let day = d.getDate();
      //console.log("day", day);
      if (day >= 13 && day <= 25) {
        this.isPadBlocked = true;
      }
    },
    selectBankImgForPad() {
      switch (this.padUIInfo.BankInstitution) {
        case "001":
          return "bmo-logo.png";
        case "002":
          return "scotia-logo.png";
        case "003":
          return "rbc-logo.png";
        case "004":
          return "td-logo.png";
        case "006":
          return "nbc-logo.png";
        case "010":
          return "cibc-logo.png";
        case "016":
          return "hsbc-logo.png";
        default:
          return "default.png";
      }
    },
    removePadFile() {
      this.fileName = "";
    },
  },
  mounted() {
    this.getProfile();
    this.validateDateForPad();
  },
};
</script>

<style lang="scss" scoped>
button {
  width: 300px;
}

.creditCard {
  border-radius: 10px;
  width: 80%;
  margin: auto;
}

.creditCard p {
  font-weight: 300;
  margin: 10px;
}

.cardNumber p {
  font-size: 20px;
}

.cardImage {
  position: absolute;
  right: 30px;
  bottom: 5px;
  width: 80px;
}

.bankLogo {
  position: absolute;
  right: 30px;
  width: 80px;
}

.terms {
  color: #1eabdc;
  cursor: pointer;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  padding: 10px 40px;
  border-radius: 50px;
  border: 0px;
  font-weight: 400;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  text-transform: uppercase;
  cursor: pointer;
}

.bordered {
  display: inline-block;
  padding: 20px;
  /* Fallback dashed border
     - the 4px width here is overwritten with the border-image-width (if set)
     - the border-image-width can be omitted below if it is the same as the 4px here
  */
  border: dashed 2px #000;

  /* Individual border image properties */
  border-image-source: url("http://i.stack.imgur.com/wLdVc.png");
  border-image-slice: 2;
  border-image-repeat: round;

  /* or use the shorthand border-image */
  border-image: url("http://i.stack.imgur.com/wLdVc.png") 2 round;
}

/*The border image of this one creates wider gaps*/
.largeGaps {
  border-image-source: url("http://i.stack.imgur.com/LKclP.png");
  margin: 0 20px;
}

.padFormText {
  font-size: 92%;
}

.longButtonText {
  padding: 10px 40px;
  width: 80%;
  border-radius: 50px;
  border: 0px;
  font-weight: 400;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  text-transform: uppercase;
}

.longButtonTextBlocked {
  padding: 10px 40px;
  width: 80%;
  border-radius: 50px;
  border: 1px solid #a5a5a5;
  font-weight: 400;
  color: #fff;
  outline: none !important;
  background-image: linear-gradient(to bottom, #a5a5a5, #a5a5a5);
  text-transform: uppercase;
}

.removeFile {
  color: #ea4335;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 768px) {
  .creditCard {
    width: 90%;
  }

  .cardImage {
    width: 60px;
    bottom: 20px;
    right: 20px;
  }

  button {
    width: 240px;
  }
}
</style>
